<template>
  <page :title="title">
    <b-container>
      <b-row class="justify-content-center">
        <b-col class="mb-4" sm="12" md="9" lg="6">
          <div v-if="formSubmitted">
            <h3>Thank you for your message.</h3>
            <p>We will get back to you shortly.</p>
          </div>
          <b-form @submit.prevent="onSubmit" v-else>
            <b-form-group label="Type of inquiry" v-slot="{ ariaDescribedby }">
              <b-form-radio-group
                id="form_contact_type"
                v-model="form.contact_type"
                :aria-describedby="ariaDescribedby"
                name="radio-sub-component"
                required
                buttons
                button-variant="outline-dark"
              >
                <b-form-radio value="customer">Customer</b-form-radio>
                <b-form-radio value="reseller"
                  >Distributor / Reseller</b-form-radio
                >
                <b-form-radio value="press">Media / Press</b-form-radio>
              </b-form-radio-group>
            </b-form-group>

            <b-form-group
              label="Email address"
              label-for="form_email"
              description="We'll never share your email with anyone else."
              label-cols="4"
            >
              <b-form-input
                id="form_email"
                type="email"
                required
                v-model="form.email"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              label="First Name"
              label-for="form_firstname"
              label-cols="4"
            >
              <b-form-input
                id="form_firstname"
                required
                v-model="form.firstname"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              label="Last Name"
              label-for="form_lastname"
              label-cols="4"
            >
              <b-form-input
                id="form_lastname"
                required
                v-model="form.lastname"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              label="Company"
              v-if="contactNeedCompany"
              label-for="form_company"
              label-cols="4"
            >
              <b-form-input
                id="form_company"
                v-model="form.company"
                required
              ></b-form-input>
            </b-form-group>

            <b-form-group
              label="Collections"
              label-cols="4"
              v-slot="{ ariaDescribedby }"
              description="Select the specific collection(s) you are interested in, if any."
            >
              <b-form-checkbox-group
                v-model="selectedCollections"
                :options="collections"
                :aria-describedby="ariaDescribedby"
              ></b-form-checkbox-group>
            </b-form-group>
            <b-form-group label="Your message" label-for="form_message">
              <b-form-textarea
                id="form_message"
                required
                v-model="form.message"
                max-rows="12"
              ></b-form-textarea>
            </b-form-group>

            <b-form-group description="*I formally agree for my data to be used by MON MIROIR to provide me with direct marketing and commercial information linked to the activity shown on this website.">
              <b-form-checkbox
                id="form_newsletter_opt_in"
                v-model="form.newsletter_opt_in"
                name="form_newsletter_opt_in"
              >
                Sign up for our newsletter*
              </b-form-checkbox>
            </b-form-group>

            <b-form-group>
              <b-form-checkbox
                id="form_pdpp_accept"
                v-model="pdpp_accept"
                name="form_pdpp_accept"
              >
                I have read, and I accept MON MIROIR’s <b-link href="/policy/privacy" target="_blank">Personal Data Protection Policy</b-link> and formally agree for my data provided herein to be used by MON MIROIR for the sole purpose of answering my request submitted on this website.
              </b-form-checkbox>
            </b-form-group>

            <b-button :disabled="!(formIsValid)" size="lg" variant="dark" type="submit">Send</b-button>
          </b-form>
        </b-col>
      </b-row>
    </b-container>
  </page>
</template>

<script>
import axios from "axios";

import Page from "./Page.vue";
import { mapGetters } from "vuex";
export default {
  props: ["collection"],
  data() {
    return {
      title: "contact",
      formSubmitted: false,
      formError: false,
      form: {
        email: "",
        company: "",
        contact_type: "",
        firstname: "",
        lastname: "",
        message: "",
        newsletter_opt_in: true,
      },
      pdpp_accept: false,
      selectedCollections: [],
      // collections: [
      //     { text: 'Intimate', value: 'Intimate' },
      //     { text: 'Paradox', value: 'Paradox' },
      //     { text: 'Intertwined', value: 'Intertwined' },
      //     { text: 'YOU', value: 'YOU' },
      // ]
    };
  },
  computed: {
    ...mapGetters({
      collections: "collectionsOptions",
    }),
    contactNeedCompany() {
      return (
        this.form.contact_type === "press" ||
        this.form.contact_type === "reseller"
      );
    },
    formIsValid() {
      return (this.pdpp_accept);
    }
    // formSubject() {
    //   return "Website Inquiry from " + this.form.firstname + " " + this.form.lastname;
    // }
  },
  methods: {
    onSubmit() {
      // TODO: VALIDATE FORM

      // Send event to GA
      this.$ga.query("send", "form", "submit", "contact");

      const postData = {
        fields: Object.entries({
          ...this.form,
          collection_interest: this.selectedCollections.join(";"),
          // subject: this.formSubject
        }).map(([name, value]) => ({ name, value })),
        context: {
          hutk: this.$cookies.get("hubspotutk"),
          pageName: document.title,
          pageUri: window.location.href,
        },
      };
      console.log(postData);
      axios
        .post(
          "https://api.hsforms.com/submissions/v3/integration/submit/9041682/5c3acf37-07e7-4712-93b0-bdd3b89fdc14",
          postData
        )
        .then((response) => {
          if (response.status === 200) {
            this.formSubmitted = true;
          } else {
            this.formError = true;
          }
        });
    },
  },
  components: {
    Page,
  },
  created() {
    // const values = this.collections.map(c => c.value);
    // console.log(values);
    if (
      this.collection &&
      this.collections.map((c) => c.value).includes(this.collection)
    ) {
      this.selectedCollections = [this.collection];
    }
  },
};
</script>

<style lang="scss" scoped>
.team-member img {
  width: 50%;
}

.team-member {
  text-align: center;
}
</style>
